import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

import styled from 'styled-components';
import Layout from '../layout/defaultLayout';
import Container from '../components/layout/Container';
// import accessTime from '../images/accessTime.svg';

const StyledFotos = styled.div`
  .fotos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .gatsby-image-wrapper {
    display: block;
    margin: 5px;
    width: 30%;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
`;

export default ({ data }) => {
  const { titel, fotos, contentful_id } = data.contentfulFotos;
  return (
    <Layout>
      <Container>
        <StyledFotos>
          <h1>{titel}</h1>
          <div className="fotos">
            {fotos.map(foto => (
              <Image key={contentful_id} fluid={foto.fluid} />
            ))}
          </div>
        </StyledFotos>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($contentfulId: String!) {
    contentfulFotos(contentful_id: { eq: $contentfulId }) {
      titel
      contentful_id
      fotos {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;
